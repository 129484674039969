import * as React from 'react';

interface INotFoundProps {
}

const NotFound: React.FunctionComponent<INotFoundProps> = (props) => {
  return (
    <>
        
      <p>NotFound page</p>
        
    </>
  );
};

export default NotFound;
