import * as React from 'react';

interface IPageHeaderProps {
    title:string,
}

const PageHeader: React.FunctionComponent<IPageHeaderProps> = ({title,...props}) => {
  return(
    <>
        <div className='pageHeading'>
            <label>{title}</label>
        </div>
    </>
  );
};

export default PageHeader;
