import { GoAButton, GoAModal } from "@abgov/react-components";
import * as React from "react";
//import { validate } from "../../utils/checkInput";

interface ITestProps {}

const Test: React.FunctionComponent<ITestProps> = (props) => {
    const [showModal, setShowModal] = React.useState(false);
    const [htmlstring] = React.useState("<p>This is a message</p><p>This is a message</p><p>This is a message</p>")

  return (
    <>
      <div className="test-form">
      <GoAButton onClick={() => setShowModal(true)}>Open Basic Modal</GoAButton>
      <GoAModal
        heading="Do you agree?"
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        
        <div dangerouslySetInnerHTML={{__html: htmlstring}} />
      </GoAModal>
      </div>
    </>
  );
};

export default Test;
